import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import useSiteMetadata from "../components/SiteMetadata";
import {Helmet} from "react-helmet";

export const OssPageTemplate = ({title, content, contentComponent}) => {
    const PageContent = contentComponent || Content

    return (
        <div className="osi-contents">
            <h1 className="title is-3">{title}</h1>
            <hr />
            <PageContent className="content" content={content}/>
        </div>
    )
}

OssPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
}

const OssPage = ({data}) => {
    const {markdownRemark: post} = data
    const {title: siteTitle} = useSiteMetadata();

    return (
        <Layout page="opensource">
            <Helmet>
                <title>{siteTitle} - {post.frontmatter.title}</title>
            </Helmet>
            <OssPageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                content={post.html}
            />
        </Layout>
    )
}

OssPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default OssPage

export const ossPageQuery = graphql`
  query OssPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
